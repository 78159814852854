$(function () {
    $('#askQuestion').on('submit', function (event) {
        event.preventDefault(); // prevent default submit behaviour
        var name = $("input#userName").val();
        var email = $("input#userEmail").val();
        /*let recaptcha = grecaptcha.getResponse();
        if (recaptcha.length == 0) return false;*/
        var message = $("textarea#userQuestion").val();
        console.log(name + " " + email + " " + message);
        $.ajax({
            url: "/recall_me.php",
            type: "POST",
            data: {name: name, email: email, message: message, /*recaptcha: recaptcha*/},
            cache: false,
            success: function () {
                $('#result').html("<div class='alert alert-success'>");
                $('#result > .alert-success').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
                    .append("</button>");
                $('#result > .alert-success')
                    .append("<strong>Ваш вопрос направлен врачу!</strong>");
                $('#result > .alert-success')
                    .append('</div>');
                //clear all fields
                $('#askQuestion').trigger("reset");

            },
            error: function () {
                // Fail message
                $('#result').html("<div class='alert alert-danger'>");
                $('#result > .alert-danger').html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;")
                    .append("</button>");
                $('#result > .alert-danger').append("Извините, произошла ошибка при отправке формы");
                $('#result > .alert-danger').append('</div>');
                //clear all fields
                $('#askQuestion').trigger("reset");
            },
        })
    });
});

$('#carouselMain').carousel();

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});

$(".menu-close").click(function () {
    $("#mobile-menu").offcanvas('hide');
});

$('.carousel').carousel({
    pause: "false"
});

$(document).ready(function () {
    if (location.hash.length > 0) {
        location.hash && $(location.hash + '.collapse').collapse('show');
        $('html, body').animate({
            scrollTop: $(location.hash).offset().top - 64
        }, 2000);
    };
    $('.carousel').carousel({
        interval: 5000
    });

    /*$('.intro-text button').click(function(){
        $(this).text(function(i,old){
            return old=='Читать далее...' ?  'Свернуть' : 'Читать далее...';
        });
    });*/

});